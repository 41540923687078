export class Produit {
    id?: number;
    codeUnique?: string;
    nom?: string;
    codeBarreId?: string;
    codeInterne?: string;
    codeFournisseur?: string;
    prixHt?: number;
    prixTTC?: number;
    invData?: string[];
}

