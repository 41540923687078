// variablesGlobales.ts
import { Injectable } from '@angular/core';
@Injectable()
export class VariablesGlobales {
    GESTION: string = '';
    COMPANY: any = '';
    URL_API_NANAKA: string = '';
    URL_API_DOLIBARR: string = '';
    URL_WebSocket: string = '';
    PREFIX_CB: string = '';
    INTEGRATION_INVENTAIRE_DOLIBARR: string = '';
    TEMPLATE_NUMBER: string = '';
    TEMPLATE_CB_NUMBER: string = '';
    IP_IMPRIMANTE_CB: string = '';
    URL_PRINTER_SERVER: string = '';
}