export class Localisation {
    id?: any;
    nom?: string;
    localisation1?: string;
    localisation2?: string;
    localisation3?: string;
    localisation4?: string;
    type?: string;
    //invData?: InvData[];
    localisations?: Localisation[];
    parentId?: number;
}

