export class InvData {
    id?: number;
    inventaireId?: string;
    qte?: number;
    time?: string;
    type?: string;
    produitId?: number;
    codeUnique?: string;
    localisationId?: number;
}

