import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Storage } from '@ionic/storage-angular';
@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    GESTION
    URL_API_NANAKA
    URL_API_DOLIBARR
    URL_WebSocket
    COMPANY;
    constructor(
        private router: Router,
        private http: HttpClient,
        private storage: Storage
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();


        this.initialisation()
    }
    async initialisation() {
        await this.storage.create();
        await this.storage.get('GESTION').then((val) => { this.GESTION = val; });
        await this.storage.get('URL_API_NANAKA').then((val) => { this.URL_API_NANAKA = val; });
        await this.storage.get('URL_API_DOLIBARR').then((val) => { this.URL_API_DOLIBARR = val; });
        await this.storage.get('URL_WebSocket').then((val) => { this.URL_WebSocket = val; });
        await this.storage.get('COMPANY').then((val) => { this.COMPANY = val; });

    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password, entity) {

        if (this.GESTION == "DOLIBARR") {
            return this.http.post<User>(`${this.URL_API_NANAKA}/users/loginDolibarr`, { username, password, entity })
                .pipe(map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));

                    this.userSubject.next(user);
                    return user;
                }));
        }
        else {
            return this.http.post<User>(`${this.URL_API_NANAKA}/users/login`, { username, password })
                .pipe(map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));

                    this.userSubject.next(user);
                    return user;
                }));
        }

    }
    getUserInfo() {
        return this.http.post<User>(`${this.URL_API_NANAKA}/whoami`, {})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));

                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    register(user: User) {
        return this.http.post(`${this.URL_API_NANAKA}/signup`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${this.URL_API_NANAKA}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${this.URL_API_NANAKA}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${this.URL_API_NANAKA}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${this.URL_API_NANAKA}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }
}