export class Inventaire {
    id?: string;
    etablissementId?: number;
    numInventaire?: string;
    date?: string;

}

export class InvEcart {
    id: string;
    inventaireId: string;
    codeUnique: string;
    localisationid: number;
    sum: number;
    stock_reel: number;
    etat: string;
    checked: boolean;
    send: Date;
    isSend: boolean;
}

