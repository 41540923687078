import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]

  },
  { path: '',
    redirectTo: 'home',
    pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'import-produits',
    loadChildren: () => import('./import-produits/import-produits.module').then(m => m.ImportProduitsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'params',
    loadChildren: () => import('./params/params.module').then(m => m.ParamsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produits',
    loadChildren: () => import('./produits/produits.module').then(m => m.ProduitsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addetablissement',
    loadChildren: () => import('./etablissement/addetablissement/addetablissement.module').then(m => m.AddetablissementPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deletablissement',
    loadChildren: () => import('./etablissement/deletablissement/deletablissement.module').then(m => m.DeletablissementPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addinventaire',
    loadChildren: () => import('./inventaire/addinventaire/addinventaire.module').then(m => m.AddinventairePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activateinventaire',
    loadChildren: () => import('./inventaire/activateinventaire/activateinventaire.module').then(m => m.ActivateinventairePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addlocalisation',
    loadChildren: () => import('./localisation/addlocalisation/addlocalisation.module').then(m => m.AddlocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dellocalisation',
    loadChildren: () => import('./localisation/dellocalisation/dellocalisation.module').then(m => m.DellocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'saisie',
    loadChildren: () => import('./inventaire/saisie/saisie.module').then(m => m.SaisiePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search-products',
    loadChildren: () => import('./modals/search-products/search-products.module').then(m => m.SearchProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'searchlocalisation',
    loadChildren: () => import('./modals/searchlocalisation/searchlocalisation.module').then(m => m.SearchlocalisationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'synthese',
    loadChildren: () => import('./synthese/synthese.module').then(m => m.SynthesePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'planning',
    loadChildren: () => import('./admin/planning/planning.module').then(m => m.PlanningPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./admin/company/company.module').then(m => m.CompanyPageModule)
  },
  {
    path: 'code-barre',
    loadChildren: () => import('./import/code-barre/code-barre.module').then(m => m.CodeBarrePageModule)
  },
  {
    path: 'choice',
    loadChildren: () => import('./gestion/choice/choice.module').then(m => m.ChoicePageModule)
  },
  {
    path: 'validation',
    loadChildren: () => import('./validation/validation.module').then(m => m.ValidationPageModule)
  },
  {
    path: 'param-url',
    loadChildren: () => import('./param-url/param-url.module').then(m => m.ParamUrlPageModule)
  },
  {
    path: 'qte-by-war-house',
    loadChildren: () => import('./modals/qte-by-war-house/qte-by-war-house.module').then(m => m.QteByWarHousePageModule)
  },
  {
    path: 'import-code-barre',
    loadChildren: () => import('./codeBarre/import-code-barre/import-code-barre.module').then( m => m.ImportCodeBarrePageModule)
  },
  {
    path: 'printer-code-barre',
    loadChildren: () => import('./codeBarre/printer-code-barre/printer-code-barre.module').then( m => m.PrinterCodeBarrePageModule)
  },
  {
    path: 'razcode-barre',
    loadChildren: () => import('./admin/razcode-barre/razcode-barre.module').then( m => m.RAZcodeBarrePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
