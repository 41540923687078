import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AccountService } from '@app/_services';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { VariablesGlobales } from '@environments/variablesGlobales';
@Injectable()


export class JwtInterceptor implements HttpInterceptor, OnInit {
    GESTION
    URL_API_NANAKA
    URL_API_DOLIBARR
    URL_WebSocket
    constructor(
        private accountService: AccountService,
        private router: Router,
        private storage: Storage,
        public variablesGlobales: VariablesGlobales
    ) { }
    ngOnInit() { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        const isLoggedIn = user && user.verificationToken;
        if (isLoggedIn && request.url.startsWith(this.variablesGlobales.URL_API_NANAKA)) {
            request = request.clone({
                setHeaders: {
                    "Authorization": `Bearer ${user.verificationToken}`,
                }
            });
        }

        if (isLoggedIn && request.url.startsWith(this.variablesGlobales.URL_API_DOLIBARR)) {


            request = request.clone({
                setHeaders: {
                    'DOLAPIKEY': ` ${user.dolibarrToken}`,
                    'DOLAPIENTITY': ` ${this.variablesGlobales.COMPANY.id}`,
                }
            });
        }

        return next.handle(request);
    }
}