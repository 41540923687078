import { Component } from '@angular/core';
import { AccountService } from './_services';
import { User } from './_models';
import { MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from '@environments/environment';
import { Storage } from '@ionic/storage-angular';
import { VariablesGlobales } from '@environments/variablesGlobales';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user: User;

  public selectedIndex = 0;
  public appPages = [
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private accountService: AccountService,
    private menu: MenuController,
    private storage: Storage,
    public variablesGlobales: VariablesGlobales

  ) {
    this.initializeApp();
    this.accountService.user.subscribe(x => this.user = x);
    this.openFirst()
  }

  async initializeApp() {
    await this.storage.create();
    await this.storage.get('GESTION').then((val) => { this.variablesGlobales.GESTION = val; });
    await this.storage.get('COMPANY').then((val) => { this.variablesGlobales.COMPANY = val; });
    await this.storage.get('URL_API_NANAKA').then((val) => { this.variablesGlobales.URL_API_NANAKA = val; });
    await this.storage.get('URL_API_DOLIBARR').then((val) => { this.variablesGlobales.URL_API_DOLIBARR = val; });
    await this.storage.get('URL_WebSocket').then((val) => { this.variablesGlobales.URL_WebSocket = val; });
    await this.storage.get('PREFIX_CB').then((val) => { this.variablesGlobales.PREFIX_CB = val; });
    await this.storage.get('TEMPLATE_NUMBER').then((val) => { this.variablesGlobales.TEMPLATE_NUMBER = val; });
    await this.storage.get('IP_IMPRIMANTE_CB').then((val) => { this.variablesGlobales.IP_IMPRIMANTE_CB = val; });
    await this.storage.get('URL_PRINTER_SERVER').then((val) => { this.variablesGlobales.URL_PRINTER_SERVER = val; });

    if (this.variablesGlobales.GESTION == 'DOLIBARR') {
      this.appPages = [
        {
          title: 'Accueil',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Consultation des produits',
          url: '/produits',
          icon: 'file-tray'
        },
        {
          title: `Saisie d'inventaire`,
          url: '/saisie',
          icon: 'create'
        },
        {
          title: `Impression Code barre`,
          url: '/printer-code-barre',
          icon: 'print'
        },
        {
          title: `consultation d'inventaire`,
          url: '/synthese',
          icon: 'download'
        },
        {
          title: `validation d'inventaire`,
          url: '/validation',
          icon: 'download'
        },
        {
          title: 'Paramétrages',
          url: '/params',
          icon: 'construct'
        },

      ];
    } else {
      this.appPages = [
        {
          title: 'Accueil',
          url: '/home',
          icon: 'home'
        },
        {
          title: 'Consultation des produits',
          url: '/produits',
          icon: 'file-tray'
        },
        {
          title: `Saisie d'inventaire`,
          url: '/saisie',
          icon: 'create'
        },
        {
          title: `consultation d'inventaire`,
          url: '/synthese',
          icon: 'download'
        },
        {
          title: 'Paramétrages',
          url: '/params',
          icon: 'construct'
        },

      ];
    }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  logout() {
    this.accountService.logout();
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

}
